<template>
    <div style="display:flex;height:100%;">
        <!-- 左侧菜单栏 -->
<!--        <div style="width:208px;">-->
        <div :class="side_collapse ? 'aside collapse':'aside'">
<!--            <aside :class="side_collapse ?'body-aside.collapse' : 'body-aside'" style="">-->
                <aside :class="side_collapse ?'body-aside collapse' : 'body-aside'" style="">

    <!--            <router-link to="main1">left main1</router-link>-->
    <!--            <router-link to="main2">left main2</router-link>-->
                <el-menu router :default-active="$route.path" active-text-color="#ffffff" background-color="#001529" text-color="hsla(0,0%,100%,.65)" :collapse="side_collapse" style="flex:1;">
                    <template v-if="false">
                        <el-sub-menu index="1">
                            <template #title>
                                <i class="el-icon-location"></i>
                                <span>导航一</span>
                            </template>
                            <el-sub-menu index="1-4">
                                <template #title>选项4</template>
                                <el-menu-item index="1-4-1">选项1</el-menu-item>
                            </el-sub-menu>
                        </el-sub-menu>
                        <el-menu-item index="2">
                            <i class="el-icon-menu"></i>
                            <template #title>导航二</template>
                        </el-menu-item>
                        <el-menu-item index="3">
                        <i class="el-icon-setting"></i>
                        <template #title>导航4</template>
                    </el-menu-item>
                    </template>
                    <el-sub-menu index="1">
                        <template #title>
                            <i class="el-icon-user"></i>
                            <span>会员管理</span>
                        </template>
                        <el-menu-item index="/b/user-statistic">会员概览</el-menu-item>
                        <el-menu-item index="/b/user-list">微信会员</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="2">
                        <template #title>
                            <i class="el-icon-house"></i>
                            <span>健身房</span>
                        </template>
                        <el-menu-item index="/b/fitness-list">健身课程</el-menu-item>
<!--                        <el-menu-item index="/b/coach-list">运动教练</el-menu-item>-->
<!--                        <el-menu-item index="/b/sport-list">课程模板</el-menu-item>-->
<!--                        <el-menu-item index="">课程预约</el-menu-item>-->
                        <el-menu-item index="/b/schedule-list">排课管理</el-menu-item>
                    </el-sub-menu>
                    <el-sub-menu index="3">
                        <template #title>
                            <i class="el-icon-s-order"></i>
                            <span>订单管理</span>
                        </template>
<!--                        <el-menu-item index="/b/order-recharge-list">会员充值</el-menu-item>-->
                        <el-menu-item index="/b/order-list">课程订单</el-menu-item>
                    </el-sub-menu>
<!--                    <el-sub-menu index="4">-->
<!--                        <template #title>-->
<!--                            <i class="el-icon-s-marketing"></i>-->
<!--                            <span>市场营销</span>-->
<!--                        </template>-->
<!--                        <el-menu-item index="/b/coupon-list">优惠券</el-menu-item>-->
<!--                        <el-menu-item index="/b/coupon-record-list">优惠券记录</el-menu-item>-->
<!--                        <el-menu-item index="/b/play-experience-package-list">试玩体验包</el-menu-item>-->
<!--                        <el-menu-item index="/b/youth-sheet-list">青训意向</el-menu-item>-->
<!--                        <el-menu-item index="/b/coach-sheet-list">私教申请</el-menu-item>-->
<!--                    </el-sub-menu>-->
                    <el-sub-menu>
                        <template #title>
                            <i class="el-icon-setting"></i>
                            <span>设置</span>
                        </template>
                        <el-menu-item index="/b/setting-group-list">参数管理</el-menu-item>
                    </el-sub-menu>
                </el-menu>
                    <el-menu>
                        <el-menu-item index="4" @click="side_collapse=!side_collapse">
                            <i v-if="!side_collapse" class="el-icon-s-fold"></i>
                            <i v-else class="el-icon-s-unfold"></i>

<!--                            <template #title>导航四</template>-->
                        </el-menu-item>
                    </el-menu>
            </aside>
        </div>
        <!-- 主页面 -->
<!--        <div style="flex:1;width:100%;">-->
            <div style="flex:1;width:calc(100% - 280px);transition:all .5s linear;">
<!--            <div style="float:left;width:100%;">-->
<!--                <div style="background-color: #ffffff;">-->
<!--                    <el-tabs type="card">-->
<!--                        <el-tab-pane label="tabs_1"></el-tab-pane>-->
<!--                        <el-tab-pane label="tabs_2"></el-tab-pane>-->
<!--                        <el-tab-pane label="tabs_3"></el-tab-pane>-->
<!--                        <el-tab-pane label="tabs_4"></el-tab-pane>-->
<!--                        <el-tab-pane label="tabs_5"></el-tab-pane>-->
<!--                    </el-tabs>-->
<!--                </div>-->
                <router-view></router-view>
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "body",
        data(){
            return {
                side_collapse:false
            }
        }
    }
</script>

<style scoped>
    /deep/.el-sub-menu__title{height:40px;line-height:40px;}
    /*/deep/.el-menu,.el-menu--collapse{ transition: all 0.3s linear;}*/
    .el-sub-menu .el-menu-item {
        height: 40px;
        line-height: 40px;
        padding: 0 45px;
        min-width: 200px;
        /*transition: all 0.3s linear;*/
    }
    .el-menu-item{
        height:40px;
        line-height:40px;
    }
    .el-menu{border-right: 0px;}
    .body-aside::-webkit-scrollbar{width:6px;}
    .body-aside::-webkit-scrollbar-thumb {
        background: hsla(0,0%,100%,.2);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px hsl(0deg 0% 100% / 5%);
    }
    .body-aside::-webkit-scrollbar-track {
        background: hsla(0,0%,100%,.15);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px rgb(37 37 37 / 5%);
    }
    /deep/.el-tabs__header{margin-bottom: 0;}


    .body-aside{
        transition: all 0.3s linear;
        width:208px;
        position: fixed;
        /*height: 100%;*/
        background:#001529;overflow:hidden auto;box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
        display:flex;
        flex-direction:column;
        bottom: 0;
        top: 40px;


    }
    .body-aside.collapse{
        transition: all 0.3s linear;
        /*width:auto;*/
        width:68px; /* 这里要设置一个宽度 否则动画不生效 不能用auto */
        /*width:auto;*/

    }

    .aside{
        transition: all 0.3s linear;
        width:208px;

    }
    .aside.collapse{
        /*width:auto;*/
        /*width:70px;*/
        width:68px;
        transition: all 0.3s linear;
    }
</style>
