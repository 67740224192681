import router from './router'
import store from './store'
import {getToken} from "@/utils/auth";
import {tokenCheck} from "@/api/user";

// const whiteList = ['/login']
router.beforeEach(async(to,from,next)=>{
    console.log('before_create')
    const hasToken = getToken()
    console.log('token:',hasToken)

    // next();
    if(hasToken){
        if(to.path === '/login'){
            next({path:'/b'})
        }else {
            //这里直接查询token有无效

            // await store.dispatch('user/getProfile')

            await tokenCheck().then(() => {
                store.dispatch('user/getProfile')
                next()
            }).catch(() => {
                // router.push('/login')
                //清除token

                next({path: '/login'})
            })
            next()
        }
    }else{
        console.log('next')
        if(to.path === '/login'){
            next();
        }else{
            next({path:'/login'})
        }
        // next({path:'/login'})
    }
})
