import { createStore } from 'vuex'
import user from './modules/user'
import getter from './getter'

export default createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        // namespaced: true,
        user
    },
    getters:getter
})
